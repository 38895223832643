import { render, staticRenderFns } from "./xinyudu.vue?vue&type=template&id=5258855d&scoped=true&"
import script from "./xinyudu.vue?vue&type=script&lang=js&"
export * from "./xinyudu.vue?vue&type=script&lang=js&"
import style0 from "./xinyudu.vue?vue&type=style&index=0&id=5258855d&lang=less&scoped=true&"
import style1 from "./xinyudu.vue?vue&type=style&index=1&lang=less&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5258855d",
  null
  
)

export default component.exports