<template>
    <div class="new-and-edit">
        <div class="item" v-for=" (from_item,item) in formData" :key="item" >
            <comFrom nameWidth="150px" v-model="from_item.value" @comchange="data_change"
                :name="from_item.id" data-vv-delay="100" :errorsInfo="errors.first(from_item.id)"
                :data='from_item' v-validate="from_item.check_type"
                @from-input="select_change"
            > 
            </comFrom>
        </div>
        <div class="footer">
            <font-button class="button" @click="saveData">保存</font-button>
        </div>
    </div>
</template>
<script>
import {ajax_get_getCore, ajax_post_setCore,} from "../../../../api/index"
export default {
    name:"xinyu",
    props:{
    },
    data(){
        return{
            formData:[],
            formDataCore:[
                {
                  id: "scoreAdd",
                  name: "增加",
                  value: '',
                  type: "number",
                  check_type: 'required',
                  placeholder: "增加信誉度",
                  unit: '',
                  slot: "",
                  options: [],
                  show:true,  
                },
                {
                  id: "scoreSubtract",
                  name: "减少",
                  value: '',
                  type: "number",
                  check_type: 'required',
                  placeholder: "减少信誉度",
                  unit: '',
                  slot: "",
                  options: [],
                  show:true,  
                },
                {
                  id: "days",
                  name: "天数",
                  value: '',
                  type: "number",
                  check_type: 'required',
                  placeholder: "天数",
                  unit: '',
                  slot: "",
                  options: [],
                  show:true,  
                },
            ],
            editId:""
        }
    },
    created(){
        this.goCore();
    },
    methods:{
        tableToForm(data){
            console.log(data,"data")
            console.log(this.formData,"data")
            this.formData.forEach(item=>{
                item.value = data[item.id]
            })
        },
        getCorePromise(){
            return new Promise((resolve, reject)=>{
              ajax_get_getCore().then(res=>{
                if(res.code == 200){
                  resolve(res.result)
                }else{
                  reject(res)
                }
              }).catch(err=>{
                reject(err)
                console.log("ajax_err:", err)
              })
            })
        },
        async goCore(){
          let coreData = await this.getCorePromise()
          console.log(coreData,"coreData")
          this.coreFlag = true;
          this.formData = this.$deepClone(this.formDataCore)
          this.tableToForm(coreData)
          this.editId = coreData.id
        },
        data_change(data,value){
            
        },
        saveData(){
            let params = this.formToSubmit("number")
            params.id = this.editId 
            if(params.scoreAdd<0 || params.scoreSubtract<0 || params.days < 0){
                this.$message.warning("请填写正整数");
                return
            }
            ajax_post_setCore(params).then(res=>{
                if(res.code == 200){
                    this.$message.success("操作成功")
                    this.formShow = false;
                }
            }).catch(err=>{
                console.log("ajax_err:",err)
            })
        },
        formToSubmit(type){
            let obj = {}
            this.formData.forEach(v=>{
                obj[v.id] = type=='number' ? Number(v.value) : v.value
            })
            return obj
        },
        select_change(data){
            
        },
    },
    watch:{
    }
}
</script>
<style lang="less" scoped>
.new-and-edit{
    padding: 10px;
    .wk-dialog__body{
        height: 500px !important;
    }
    .item{
        margin: 10px;
    }
    .footer{
        width: 30%;
    }
}
</style>
<style lang="less">
.planHeight{
    .wk-dialog__body{
        height: 600px !important;
    }
}
</style>